import Vue from "vue";
import VueRouter from "vue-router";
import Empty from "@/views/EmptyView.vue";
import VueGtag from "vue-gtag";

Vue.use(VueRouter);

const routes = [
  {
    path: "/auth",
    name: "Auth",
    component: () => import(/* webpackChunkName: "login" */ "@/views/Login/LoginMainScreen.vue"),
    redirect: { name: "AccountLogin" },
    children: [
      {
        path: "login",
        name: "Login",
        redirect: { name: "AccountLogin" },
        component: () => import(/* webpackChunkName: "login" */ "@/views/Login/LoginView.vue"),
        children: [
          {
            path: "account",
            name: "AccountLogin",
            component: () => import(/* webpackChunkName: "login" */ "@/views/Login/AccountLogin.vue"),
            children: [],
          },
          {
            path: "qrcore",
            name: "QRCodeLogin",
            component: () => import(/* webpackChunkName: "login" */ "@/views/Login/QRCodeLogin.vue"),
            children: [],
          },
          {
            path: "scan",
            name: "ScanLogin",
            component: () => import(/* webpackChunkName: "login" */ "@/views/Login/ScanLogin.vue"),
            children: [],
          },
        ],
      },
      {
        path: "register",
        name: "Register",
        component: () => import(/* webpackChunkName: "main" */ "@/views/Login/RegisterView.vue"),
        children: [],
      },
      {
        path: "resetpassword",
        name: "ResetPassword",
        component: () => import(/* webpackChunkName: "main" */ "@/views/Login/ResetPasswordView.vue"),
        children: [],
      },
      {
        path: "communityselect",
        name: "CommunitySelect",
        component: () => import(/* webpackChunkName: "main" */ "@/views/Login/CommunitySelect.vue"),
        meta: {
          auth: true,
        },
        children: [],
      },
    ],
  },
  {
    path: "/privacy",
    name: "PrivacyPolicy",
    component: () => import(/* webpackChunkName: "privacy" */ "@/views/PrivacyPolicy.vue"),
    children: [],
  },
  {
    path: "/datasafe",
    name: "DataSafe",
    component: () => import(/* webpackChunkName: "datasafe" */ "@/views/DataSafe.vue"),
    children: [],
  },
  // {
  //   path: "/pay/sinopac/mgmt_fee/:communityID/:orderID",
  //   // name: "PaySinopac",
  //   component: () => import(/* webpackChunkName: "pay-sinopac" */ "@/views/Pay/Sinopac/MgmtFeeReturnView.vue"),
  //   children: [],
  //   props: true,
  // },
  {
    path: "/",
    name: "MainScreen",
    component: () => import(/* webpackChunkName: "main" */ "@/views/MainScreen.vue"),
    redirect: { name: "Menu" },
    children: [
      {
        path: "menu",
        name: "Menu",
        component: () => import(/* webpackChunkName: "main" */ "@/views/MenuView.vue"),
        meta: {
          auth: true,
        },
        children: [],
      },
      {
        path: "postal",
        component: Empty,
        redirect: { name: "Menu" },
        meta: {
          auth: true,
          function: "community.mail",
        },
        children: [
          {
            path: "despatcher",
            name: "PostalDespatcher",
            component: () => import(/* webpackChunkName: "postal" */ "@/views/Postal/PostalDespatcher.vue"),
            meta: {
              auth: true,
              function: "community.mail",
            },
            children: [],
          },
          {
            path: "receive",
            name: "PostalReceive",
            component: () => import(/* webpackChunkName: "postal" */ "@/views/Postal/PostalReceive.vue"),
            meta: {
              auth: true,
              function: "community.mail",
            },
            children: [],
          },
          {
            path: "list",
            name: "PostalList",
            component: () => import(/* webpackChunkName: "postal" */ "@/views/Postal/PostalList.vue"),
            meta: {
              auth: true,
              function: "community.mail",
            },
            props: true,
            children: [],
          },
          {
            path: "return",
            name: "PostalReturn",
            component: () => import(/* webpackChunkName: "postal" */ "@/views/Postal/PostalReturn.vue"),
            meta: {
              auth: true,
              function: "community.mail",
            },
            children: [],
          },
          {
            path: "place",
            name: "PostalPlace",
            component: () => import(/* webpackChunkName: "postal" */ "@/views/Postal/PostalPlace.vue"),
            meta: {
              auth: true,
              function: "community.mail",
            },
            children: [],
          },
        ],
      },
      {
        path: "guest",
        component: Empty,
        redirect: { name: "Menu" },
        meta: {
          auth: true,
          function: "community.guest",
        },
        children: [
          {
            path: "check",
            name: "GuestCheck",
            component: () => import(/* webpackChunkName: "guest" */ "@/views/Guest/GuestCheck.vue"),
            meta: {
              auth: true,
              function: "community.guest",
            },
            children: [],
          },
          {
            path: "borrowitem",
            name: "GuestBorrowItem",
            component: () => import(/* webpackChunkName: "guest" */ "@/views/Guest/GuestBorrowItem.vue"),
            meta: {
              auth: true,
              function: "community.guest",
            },
            children: [],
          },
          {
            path: "visitplace",
            name: "GuestVisitPlace",
            component: () => import(/* webpackChunkName: "guest" */ "@/views/Guest/GuestVisitPlace.vue"),
            meta: {
              auth: true,
              function: "community.guest",
            },
            children: [],
          },
          {
            path: "leave",
            name: "GuestLeave",
            component: () => import(/* webpackChunkName: "guest" */ "@/views/Guest/GuestLeave.vue"),
            meta: {
              auth: true,
              function: "community.guest",
            },
            children: [],
          },
          {
            path: "history",
            name: "GuestHistory",
            component: () => import(/* webpackChunkName: "guest" */ "@/views/Guest/GuestHistory.vue"),
            meta: {
              auth: true,
              function: "community.guest",
            },
            children: [],
          },
        ],
      },
      {
        path: "householdactive",
        component: Empty,
        redirect: { name: "Menu" },
        meta: {
          auth: true,
          function: "community.household",
        },
        children: [
          {
            path: "menu",
            name: "HouseHoldActiveList",
            component: () => import(/* webpackChunkName: "householdactive" */ "@/views/HouseHoldActive/MainScreen.vue"),
            meta: {
              auth: true,
              function: "community.household",
            },
            children: [],
          },
        ],
      },
      {
        path: "householddetail",
        component: Empty,
        redirect: { name: "Menu" },
        meta: {
          auth: true,
          function: "community.household",
        },
        children: [
          {
            path: "view",
            name: "HouseHoldDetail",
            component: () => import(/* webpackChunkName: "householddetail" */ "@/views/HouseHoldActive/HouseHoldDetail.vue"),
            meta: {
              auth: true,
              function: "community.household",
            },
            props: true,
            children: [],
          },
        ],
      },
      {
        path: "notification",
        component: Empty,
        redirect: { name: "Menu" },
        meta: {
          auth: true,
          function: "community.notification",
        },
        children: [
          {
            path: "menu",
            name: "NotificationMainScreen",
            component: () => import(/* webpackChunkName: "notification" */ "@/views/Notification/NotificationMainScreen.vue"),
            meta: {
              auth: true,
              function: "community.notification",
            },
            children: [],
          },
          {
            path: "list",
            name: "NotificationList",
            component: () => import(/* webpackChunkName: "notification" */ "@/views/Notification/NotificationList.vue"),
            meta: {
              auth: true,
              function: "community.notification",
            },
            children: [],
          },
        ],
      },
      {
        path: "deposit",
        component: Empty,
        redirect: { name: "Menu" },
        meta: {
          auth: true,
          function: "community.deposit",
        },
        children: [
          {
            path: "check",
            name: "DepositCheck",
            component: () => import(/* webpackChunkName: "deposit" */ "@/views/Deposit/DepositCheck.vue"),
            meta: {
              auth: true,
              function: "community.deposit",
            },
            children: [],
          },
          {
            path: "list",
            name: "DepositList",
            component: () => import(/* webpackChunkName: "deposit" */ "@/views/Deposit/DepositList.vue"),
            meta: {
              auth: true,
              function: "community.deposit",
            },
            children: [],
          },
          {
            path: "place",
            name: "DepositPlace",
            component: () => import(/* webpackChunkName: "deposit" */ "@/views/Deposit/DepositPlace.vue"),
            meta: {
              auth: true,
              function: "community.deposit",
            },
            children: [],
          },
        ],
      },
      {
        path: "point",
        component: Empty,
        redirect: { name: "Menu" },
        meta: {
          auth: true,
          function: "community.point",
        },
        children: [
          {
            path: "manage",
            name: "PointManage",
            component: () => import(/* webpackChunkName: "point" */ "@/views/Point/PointManage.vue"),
            meta: {
              auth: true,
              function: "community.point",
            },
            children: [],
          },
          {
            path: "history",
            name: "PointHistory",
            component: () => import(/* webpackChunkName: "point" */ "@/views/Point/PointHistory.vue"),
            meta: {
              auth: true,
              function: "community.point",
            },
            children: [],
          },
          {
            path: "list",
            name: "PointList",
            component: () => import(/* webpackChunkName: "point" */ "@/views/Point/PointList.vue"),
            meta: {
              auth: true,
              function: "community.point",
            },
            children: [],
          },
          {
            path: "itemmanage",
            name: "PointItemManage",
            component: () => import(/* webpackChunkName: "point" */ "@/views/Point/PointItemManage.vue"),
            meta: {
              auth: true,
              function: "community.point",
            },
            children: [],
          },
        ],
      },
      {
        path: "facility",
        component: Empty,
        redirect: { name: "Menu" },
        meta: {
          auth: true,
          function: "community.booking",
        },
        children: [
          {
            path: "list",
            name: "FacilityList",
            component: () => import(/* webpackChunkName: "facility" */ "@/views/Facility/FacilityList.vue"),
            meta: {
              auth: true,
              function: "community.booking",
            },
            children: [],
          },
          {
            path: "edit",
            name: "FacilityEdit",
            component: () => import(/* webpackChunkName: "facility" */ "@/views/Facility/FacilityEdit.vue"),
            meta: {
              auth: true,
              function: "community.booking",
            },
            props: true,
            children: [],
          },
          {
            path: "bookinglist",
            name: "FacilityBookingList",
            component: () => import(/* webpackChunkName: "facility" */ "@/views/Facility/FacilityBookingList.vue"),
            meta: {
              auth: true,
              function: "community.booking",
            },
            children: [],
          },
          {
            path: "borrowitem",
            name: "FacilityBorrowItem",
            component: () => import(/* webpackChunkName: "facility" */ "@/views/Facility/FacilityBorrowItem.vue"),
            meta: {
              auth: true,
              function: "community.booking",
            },
            children: [],
          },
        ],
      },
      {
        path: "news",
        component: Empty,
        redirect: { name: "Menu" },
        meta: {
          auth: true,
          function: "community.announcement",
        },
        children: [
          {
            path: "list",
            name: "NewsList",
            component: () => import(/* webpackChunkName: "news" */ "@/views/News/NewsList.vue"),
            meta: {
              auth: true,
              function: "community.announcement",
            },
            children: [],
          },
          {
            path: "edit",
            name: "NewsEdit",
            component: () => import(/* webpackChunkName: "news" */ "@/views/News/NewsEdit.vue"),
            meta: {
              auth: true,
              function: "community.announcement",
            },
            props: true,
            children: [],
          },
          {
            path: "view",
            name: "NewsView",
            component: () => import(/* webpackChunkName: "news" */ "@/views/News/NewsView.vue"),
            meta: {
              auth: true,
              function: "community.announcement",
            },
            props: true,
            children: [],
          },
          {
            path: "type",
            name: "NewsType",
            component: () => import(/* webpackChunkName: "news" */ "@/views/News/NewsType.vue"),
            meta: {
              auth: true,
              function: "community.announcement",
            },
            children: [],
          },
        ],
      },
      {
        path: "feedback",
        component: Empty,
        redirect: { name: "Menu" },
        meta: {
          auth: true,
          function: "community.feedback",
        },
        children: [
          {
            path: "list",
            name: "FeedbackList",
            component: () => import(/* webpackChunkName: "feedback" */ "@/views/Feedback/FeedbackList.vue"),
            meta: {
              auth: true,
              function: "community.feedback",
            },
            children: [],
          },
          {
            path: "type",
            name: "FeedbackType",
            component: () => import(/* webpackChunkName: "feedback" */ "@/views/Feedback/FeedbackType.vue"),
            meta: {
              auth: true,
              function: "community.feedback",
            },
            children: [],
          },
          {
            path: "reply",
            name: "FeedbackReply",
            component: () => import(/* webpackChunkName: "feedback" */ "@/views/Feedback/FeedbackReply.vue"),
            meta: {
              auth: true,
              function: "community.feedback",
            },
            props: true,
            children: [],
          },
        ],
      },
      {
        path: "rules",
        component: Empty,
        redirect: { name: "Menu" },
        meta: {
          auth: true,
          function: "community.statute",
        },
        children: [
          {
            path: "list",
            name: "RulesList",
            component: () => import(/* webpackChunkName: "rules" */ "@/views/Rules/RulesList.vue"),
            meta: {
              auth: true,
              function: "community.statute",
            },
            children: [],
          },
          {
            path: "edit",
            name: "RulesEdit",
            component: () => import(/* webpackChunkName: "rules" */ "@/views/Rules/RulesEdit.vue"),
            meta: {
              auth: true,
              function: "community.statute",
            },
            props: true,
            children: [],
          },
          {
            path: "view",
            name: "RulesView",
            component: () => import(/* webpackChunkName: "rules" */ "@/views/Rules/RulesView.vue"),
            meta: {
              auth: true,
              function: "community.statute",
            },
            props: true,
            children: [],
          },
          {
            path: "type",
            name: "RulesType",
            component: () => import(/* webpackChunkName: "news" */ "@/views/Rules/RulesType.vue"),
            meta: {
              auth: true,
              function: "community.statute",
            },
            children: [],
          },
        ],
      },
      {
        path: "vote",
        component: Empty,
        redirect: { name: "Menu" },
        meta: {
          auth: true,
          function: "community.vote",
        },
        children: [
          {
            path: "list",
            name: "VoteList",
            component: () => import(/* webpackChunkName: "vote" */ "@/views/Vote/VoteList.vue"),
            meta: {
              auth: true,
              function: "community.vote",
            },
            children: [],
          },
          {
            path: "edit",
            name: "VoteEdit",
            component: () => import(/* webpackChunkName: "vote" */ "@/views/Vote/VoteEdit.vue"),
            meta: {
              auth: true,
              function: "community.vote",
            },
            props: true,
            children: [],
          },
          {
            path: "detail",
            name: "VoteDetail",
            component: () => import(/* webpackChunkName: "vote" */ "@/views/Vote/VoteDetail.vue"),
            meta: {
              auth: true,
              function: "community.vote",
            },
            props: true,
            children: [],
          },
        ],
      },
      {
        path: "gas",
        component: Empty,
        redirect: { name: "Menu" },
        meta: {
          auth: true,
          function: "community.gas",
        },
        children: [
          {
            path: "list",
            name: "GasList",
            component: () => import(/* webpackChunkName: "gas" */ "@/views/Gas/GasList.vue"),
            meta: {
              auth: true,
              function: "community.gas",
            },
            children: [],
          },
          {
            path: "detail",
            name: "GasDetail",
            component: () => import(/* webpackChunkName: "gas" */ "@/views/Gas/GasDetail.vue"),
            meta: {
              auth: true,
              function: "community.gas",
            },
            props: true,
            children: [],
          },
        ],
      },
      {
        path: "calendar",
        component: Empty,
        redirect: { name: "Menu" },
        meta: {
          auth: true,
          function: "community.calendar",
        },
        children: [
          {
            path: "view",
            name: "CalendarView",
            component: () => import(/* webpackChunkName: "calendar" */ "@/views/Calendar/CalendarView.vue"),
            meta: {
              auth: true,
              function: "community.calendar",
            },
            children: [],
          },
        ],
      },
      {
        path: "mgmtfee",
        component: Empty,
        redirect: { name: "Menu" },
        meta: {
          auth: true,
          function: "community.managementfee",
        },
        children: [
          {
            path: "list",
            name: "MgmtFeeList",
            component: () => import(/* webpackChunkName: "mgmtfee" */ "@/views/MgmtFee/MgmtFeeList.vue"),
            meta: {
              auth: true,
              module: "module.managementFee",
              function: "community.managementfee",
            },
            children: [],
          },
          {
            path: "setting",
            name: "MgmtFeeSetting",
            component: () => import(/* webpackChunkName: "mgmtfee" */ "@/views/MgmtFee/MgmtFeeSetting.vue"),
            meta: {
              auth: true,
              module: "module.managementFee",
              function: "community.managementfee",
            },
            props: true,
            children: [],
          },
          {
            path: "payinfo",
            name: "MgmtFeePayInfo",
            component: () => import(/* webpackChunkName: "mgmtfee" */ "@/views/MgmtFee/MgmtFeePayInfo.vue"),
            meta: {
              auth: true,
              module: "module.managementFee",
              function: "community.managementfee",
            },
            props: true,
            children: [],
          },
          {
            path: "item",
            name: "MgmtFeeSettingItem",
            component: () => import(/* webpackChunkName: "mgmtfee" */ "@/views/MgmtFee/MgmtFeeItem.vue"),
            meta: {
              auth: true,
              module: "module.managementFee",
              function: "community.managementfee",
            },
            props: true,
            children: [],
          },
        ],
      },
      {
        path: "cctv",
        component: Empty,
        redirect: { name: "Menu" },
        meta: {
          auth: true,
          function: "community.cctv",
          module: "module.cctv",
        },
        children: [
          {
            path: "list",
            name: "CCTVList",
            component: () => import(/* webpackChunkName: "cctv" */ "@/views/CCTV/CCTVList.vue"),
            meta: {
              auth: true,
              function: "community.cctv",
              module: "module.cctv",
            },
            children: [],
          },
          {
            path: "cms",
            name: "CCTVCMS",
            component: () => import(/* webpackChunkName: "cctv" */ "@/views/CCTV/CMS.vue"),
            meta: {
              auth: true,
              function: "community.cctv",
              module: "module.cctv",
            },
            children: [],
          },
        ],
      },
      {
        path: "charge",
        component: Empty,
        // component: () => import(/* webpackChunkName: "charge" */ "@/views/Charge/ChargeMenu.vue"),
        redirect: { name: "ChargeDashboard" },
        meta: {
          auth: true,
          function: "community.charge",
          module: "module.charge",
        },
        children: [
          {
            path: "dashboard",
            name: "ChargeDashboard",
            component: () => import(/* webpackChunkName: "charge" */ "@/views/Charge/ChargeDashboard.vue"),
            meta: {
              auth: true,
              function: "community.charge",
              module: "module.charge",
            },
            children: [],
          },
          {
            path: "total",
            name: "ChargeTotal",
            component: () => import(/* webpackChunkName: "charge" */ "@/views/Charge/ChargeDashboard.vue"),
            meta: {
              auth: true,
              function: "community.charge",
              module: "module.charge",
            },
            children: [],
          },
          {
            path: "device",
            name: "ChargeDevice",
            component: () => import(/* webpackChunkName: "charge" */ "@/views/Charge/ChargeDashboard.vue"),
            meta: {
              auth: true,
              function: "community.charge",
              module: "module.charge",
            },
            children: [],
          },
          {
            path: "setting",
            name: "ChargeSetting",
            component: () => import(/* webpackChunkName: "charge" */ "@/views/Charge/ChargeDashboard.vue"),
            meta: {
              auth: true,
              function: "community.charge",
              module: "module.charge",
            },
            children: [],
          },
        ],
      },
      {
        path: "intercom",
        component: Empty,
        redirect: { name: "IntercomList" },
        meta: {
          auth: true,
          function: "community.intercom",
          module: "module.intercom",
        },
        children: [
          {
            path: "list",
            name: "IntercomList",
            redirect: { name: "IntercomStationList" },
            component: () => import(/* webpackChunkName: "intercom" */ "@/views/Intercom/IntercomList.vue"),
            meta: {
              auth: true,
              function: "community.intercom",
              module: "module.intercom",
            },
            children: [
              {
                path: "station",
                name: "IntercomStationList",
                component: () => import(/* webpackChunkName: "intercom" */ "@/views/Intercom/IntercomStationList.vue"),
                meta: {
                  auth: true,
                  function: "community.intercom",
                  module: "module.intercom",
                },
                children: [],
              },
              {
                path: "household",
                name: "IntercomHouseHoldList",
                component: () => import(/* webpackChunkName: "intercom" */ "@/views/Intercom/IntercomHouseHoldList.vue"),
                meta: {
                  auth: true,
                  function: "community.intercom",
                  module: "module.intercom",
                },
                children: [],
              },
            ],
          },
          // {
          //   path: "total",
          //   name: "ChargeTotal",
          //   component: () => import(/* webpackChunkName: "charge" */ "@/views/Charge/ChargeDashboard.vue"),
          //   meta: {
          //     auth: true,
          //     function: "community.charge",
          //     module: "module.charge",
          //   },
          //   children: [],
          // },
          // {
          //   path: "device",
          //   name: "ChargeDevice",
          //   component: () => import(/* webpackChunkName: "charge" */ "@/views/Charge/ChargeDashboard.vue"),
          //   meta: {
          //     auth: true,
          //     function: "community.charge",
          //     module: "module.charge",
          //   },
          //   children: [],
          // },
          // {
          //   path: "setting",
          //   name: "ChargeSetting",
          //   component: () => import(/* webpackChunkName: "charge" */ "@/views/Charge/ChargeDashboard.vue"),
          //   meta: {
          //     auth: true,
          //     function: "community.charge",
          //     module: "module.charge",
          //   },
          //   children: [],
          // },
        ],
      },
      {
        path: "account",
        component: Empty,
        redirect: { name: "Menu" },
        meta: {
          auth: true,
          function: "community.account",
        },
        children: [
          {
            path: "manage",
            name: "AccountMainScreen",
            redirect: { name: "AccountList" },
            component: () => import(/* webpackChunkName: "account" */ "@/views/Account/AccountMainScreen.vue"),
            meta: {
              auth: true,
              function: "community.account",
            },
            children: [
              {
                path: "list",
                name: "AccountList",
                component: () => import(/* webpackChunkName: "account" */ "@/views/Account/AccountList.vue"),
                meta: {
                  auth: true,
                  function: "community.account",
                },
                children: [],
              },
              {
                path: "code_list",
                name: "AccountCodeList",
                component: () => import(/* webpackChunkName: "account" */ "@/views/Account/AccountCodeList.vue"),
                meta: {
                  auth: true,
                  function: "community.account",
                },
                children: [],
              },
              {
                path: "role_list",
                name: "AccountRoleList",
                component: () => import(/* webpackChunkName: "account" */ "@/views/Account/AccountRoleList.vue"),
                meta: {
                  auth: true,
                  function: "community.account",
                },
                children: [],
              },
            ],
          },
          // {
          //   path: "detail",
          //   name: "GasDetail",
          //   component: () => import(/* webpackChunkName: "account" */ "@/views/Gas/GasDetail.vue"),
          //   meta: {
          //     auth: true,
          //   },
          //   props: true,
          //   children: [],
          // },
        ],
      },
    ],
  },
  {
    path: "/fm",
    component: () => import(/* webpackChunkName: "fm_main" */ "@/views/FM/MainScreen.vue"),
    meta: {
      auth: true,
      function: "community.asset",
      module: "module.asset",
    },
    redirect: { name: "FMMainScreen" },
    children: [
      {
        path: "dashboard",
        name: "FMMainScreen",
        component: () => import(/* webpackChunkName: "fm_main" */ "@/views/FM/FMDashboard.vue"),
        meta: {
          auth: true,
          function: "community.asset",
          module: "module.asset",
        },
        children: [],
      },
      {
        path: "asset",
        component: Empty,
        // component: () => import(/* webpackChunkName: "asset" */ "@/views/Asset/AssetMenu.vue"),
        redirect: { name: "AssetOverview" },
        meta: {
          auth: true,
          function: "community.asset",
          module: "module.asset",
        },
        children: [
          {
            path: "overview",
            name: "AssetOverview",
            component: () => import(/* webpackChunkName: "asset" */ "@/views/FM/Asset/AssetOverview.vue"),
            meta: {
              auth: true,
              function: "community.asset",
              module: "module.asset",
            },
            children: [],
          },
          {
            path: "list",
            name: "AssetList",
            props: true,
            component: () => import(/* webpackChunkName: "asset" */ "@/views/FM/Asset/AssetList.vue"),
            meta: {
              auth: true,
              function: "community.asset",
              module: "module.asset",
            },
            children: [],
          },
          {
            path: "view",
            name: "AssetView",
            props: true,
            component: () => import(/* webpackChunkName: "asset" */ "@/views/FM/Asset/AssetView.vue"),
            meta: {
              auth: true,
              function: "community.asset",
              module: "module.asset",
            },
            children: [],
          },
          {
            path: "type_list",
            name: "AssetTypeList",
            component: () => import(/* webpackChunkName: "asset" */ "@/views/FM/Asset/AssetTypeList.vue"),
            meta: {
              auth: true,
              function: "community.asset",
              module: "module.asset",
            },
            children: [],
          },
          {
            path: "place_list",
            name: "AssetPlaceList",
            component: () => import(/* webpackChunkName: "asset" */ "@/views/FM/Asset/AssetPlaceList.vue"),
            meta: {
              auth: true,
              function: "community.asset",
              module: "module.asset",
            },
            children: [],
          },
        ],
      },
      {
        path: "storage",
        component: Empty,
        redirect: { name: "StorageList" },
        meta: {
          auth: true,
          function: "community.storage",
          module: "module.storage",
        },
        children: [
          {
            path: "list",
            name: "StorageList",
            props: true,
            component: () => import(/* webpackChunkName: "storage" */ "@/views/FM/Storage/StorageList.vue"),
            meta: {
              auth: true,
              function: "community.storage",
              module: "module.storage",
            },
            children: [],
          },
          // {
          //   path: "view",
          //   name: "StorageView",
          //   props: true,
          //   component: () => import(/* webpackChunkName: "storage" */ "@/views/FM/Storage/StorageView.vue"),
          //   meta: {
          //     auth: true,
          //     function: "community.storage",
          //     module: "module.storage",
          //   },
          //   children: [],
          // },
          {
            path: "type_list",
            name: "StorageTypeList",
            component: () => import(/* webpackChunkName: "storage" */ "@/views/FM/Storage/StorageTypeList.vue"),
            meta: {
              auth: true,
              function: "community.storage",
              module: "module.storage",
            },
            children: [],
          },
          {
            path: "place_list",
            name: "StoragePlaceList",
            component: () => import(/* webpackChunkName: "storage" */ "@/views/FM/Storage/StoragePlaceList.vue"),
            meta: {
              auth: true,
              function: "community.storage",
              module: "module.storage",
            },
            children: [],
          },
          {
            path: "unit_list",
            name: "StorageUnitList",
            component: () => import(/* webpackChunkName: "storage" */ "@/views/FM/Storage/StorageUnitList.vue"),
            meta: {
              auth: true,
              function: "community.storage",
              module: "module.storage",
            },
            children: [],
          },
        ],
      },
      {
        path: "vendor",
        component: Empty,
        // component: () => import(/* webpackChunkName: "vendor" */ "@/views/Asset/AssetMenu.vue"),
        redirect: { name: "VendorList" },
        meta: {
          auth: true,
          function: "community.asset",
          module: "module.asset",
        },
        children: [
          {
            path: "list",
            name: "VendorList",
            component: () => import(/* webpackChunkName: "vendor" */ "@/views/FM/Vendor/VendorList.vue"),
            meta: {
              auth: true,
              function: "community.asset",
              module: "module.asset",
            },
            children: [],
          },
        ],
      },
    ],
  },
  {
    path: "/admin",
    name: "AdminMainScreen",
    component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/MainScreen.vue"),
    redirect: { name: "AdminDashboard" },
    children: [
      {
        path: "dashboard",
        name: "AdminDashboard",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Dashboard.vue"),
        meta: {
          admin: true,
        },
        children: [],
      },
      {
        path: "community",
        component: Empty,
        redirect: { name: "AdminDashboard" },
        meta: {
          auth: true,
        },
        children: [
          {
            path: "list",
            name: "CommunityList",
            component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Community/CommunityList.vue"),
            meta: {
              admin: true,
            },
            children: [],
          },
          {
            path: "moduleList",
            name: "CommunityModuleList",
            component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Community/CommunityModuleList.vue"),
            meta: {
              admin: true,
            },
            props: true,
            children: [],
          },
        ],
      },
      {
        path: "communityAdminUserList",
        name: "CommunityAdminUserList",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Community/CommunityAdminUserList.vue"),
        meta: {
          admin: true,
        },
        props: true,
        children: [],
      },
      {
        path: "communityDoorList",
        name: "CommunityDoorList",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Community/CommunityDoorList.vue"),
        meta: {
          admin: true,
        },
        props: true,
        children: [],
      },
      {
        path: "communityDefaultRoleList",
        name: "CommunityDefaultRoleList",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Community/Role/CommunityDefaultRoleList.vue"),
        meta: {
          admin: true,
        },
        children: [],
      },
      {
        path: "communityPermissionList",
        name: "CommunityPermissionList",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Community/CommunityPermissionList.vue"),
        meta: {
          admin: true,
        },
        children: [],
      },
      {
        path: "communityDefaultFacilityTypeList",
        name: "CommunityDefaultFacilityTypeList",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Community/CommunityDefaultFacilityTypeList.vue"),
        meta: {
          admin: true,
        },
        children: [],
      },
      {
        path: "communityDefaultDepositTypeList",
        name: "CommunityDefaultDepositTypeList",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Community/CommunityDefaultDepositTypeList.vue"),
        meta: {
          admin: true,
        },
        children: [],
      },
      {
        path: "communityDefaultPackageTypeList",
        name: "CommunityDefaultPackageTypeList",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Community/Package/CommunityDefaultPackageTypeList.vue"),
        meta: {
          admin: true,
        },
        children: [],
      },
      {
        path: "communityDefaultShippingFromList",
        name: "CommunityDefaultShippingFromList",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Community/Package/CommunityDefaultShippingFromList.vue"),
        meta: {
          admin: true,
        },
        children: [],
      },
      {
        path: "communityDefaultShippingProviderList",
        name: "CommunityDefaultShippingProviderList",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Community/Package/CommunityDefaultShippingProviderList.vue"),
        meta: {
          admin: true,
        },
        children: [],
      },
      {
        path: "communityDefaultCompanyList",
        name: "CommunityDefaultCompanyList",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Community/CommunityDefaultCompanyList.vue"),
        meta: {
          admin: true,
        },
        children: [],
      },
      {
        path: "communityDefaultVisitReasonList",
        name: "CommunityDefaultVisitReasonList",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Community/CommunityDefaultVisitReasonList.vue"),
        meta: {
          admin: true,
        },
        children: [],
      },
      {
        path: "moduleList",
        name: "ModuleList",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/SystemSetting/ModuleList.vue"),
        meta: {
          admin: true,
        },
        children: [],
      },
      {
        path: "permissionList",
        name: "PermissionList",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/SystemSetting/PermissionList.vue"),
        meta: {
          admin: true,
        },
        children: [],
      },
      {
        path: "roleList",
        name: "RoleList",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/SystemSetting/RoleList.vue"),
        meta: {
          admin: true,
        },
        children: [],
      },
      {
        path: "userList",
        name: "UserList",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Account/UserList.vue"),
        meta: {
          admin: true,
        },
        children: [],
      },
      {
        path: "notify",
        name: "SystemNotify",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Notify/NotifyMainscreen.vue"),
        meta: {
          admin: true,
        },
        children: [],
      },
      {
        path: "sinopacPaySetting",
        name: "SinopacPaySetting",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Pay/SinopacPaySetting.vue"),
        meta: {
          admin: true,
        },
        props: true,
        children: [],
      },
      {
        path: "localServerAuth",
        name: "LocalServerAuth",
        component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Community/LocalServerAuth.vue"),
        meta: {
          admin: true,
        },
        props: true,
        children: [],
      },
      {
        path: "service",
        component: Empty,
        redirect: { name: "ThirdPartyServiceList" },
        meta: {
          auth: true,
        },
        children: [
          {
            path: "list",
            name: "ThirdPartyServiceList",
            component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/ThirdPartyService/ThirdPartyServiceList.vue"),
            meta: {
              admin: true,
            },
            children: [],
          },
          {
            path: "intercom",
            component: Empty,
            redirect: { name: "ThirdPartyServiceIntercomStationList" },
            meta: {
              auth: true,
            },
            children: [
              {
                path: "list",
                name: "ThirdPartyServiceIntercomStationList",
                component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/ThirdPartyService/Intercom/StationList.vue"),
                meta: {
                  admin: true,
                },
                props: true,
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },

  // {
  //   path: "/webrtc",
  //   name: "WebRTC",
  //   component: () => import(/* webpackChunkName: "test" */ "@/views/ChatRoom/MainScreen4.vue"),
  //   children: [],
  // },
  // {
  //   path: "/webrtc2",
  //   name: "WebRTC2",
  //   component: () => import(/* webpackChunkName: "test" */ "@/views/ChatRoom/MainScreen2.vue"),
  //   children: [],
  // },
  // {
  //   path: "/testcal",
  //   name: "TestCal",
  //   component: () => import(/* webpackChunkName: "test" */ "@/views/TestCal.vue"),
  //   children: [],
  // },
  {
    path: "*",
    component: Empty,
    redirect: { name: "AccountLogin" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

Vue.use(
  VueGtag,
  {
    config: { id: "G-CBSYZ99F9Y" },
  },
  router,
);

export default router;
